<template>
	<main>
		<HeaderTab :title="$t('global.titre_tiers_search')"  :return_action="'navigationBack'"/>

		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
							<SearchQualification qualif_type="tiers" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script type="text/javascript">

export default {
	name: "TiersSearch",
	mixins: [],
	data () {
		return {
		}
	},
	methods: {
	},
	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
		SearchQualification : () => import('@/components/Qualification/SearchQualification'),
	}
}
</script>
